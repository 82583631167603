<template>
  <router-layout>
    <div class="page">
      <div class="form">
        <div class="form_t">基本信息</div>
        <div class="input">
          <van-field
            v-model="type"
            input-align="right"
            readonly
            required
            label="类别"
          />
        </div>
        <div class="input">
          <van-field
            v-model="title"
            clearable
            input-align="right"
            required
            label="标题"
            placeholder="请输标题,40字以内"
            maxlength="40"
          />
        </div>
        <div class="input">
          <van-field
            v-model="area"
            input-align="right"
            is-link
            readonly
            required
            label="区域"
            placeholder="请选择"
            @click="areaPicker"
          />
        </div>
        <div class="input noborder">
          <van-field
            v-model="time"
            input-align="right"
            is-link
            readonly
            required
            label="有效期"
            placeholder="请选择"
            @click="timePicker"
          />
        </div>
        <div class="line"></div>
        <div class="input">
          <van-field v-model="name" input-align="right" required label="联系人" placeholder="请输入" />
        </div>
        <div class="input">
          <van-field
            v-model="phone"
            input-align="right"
            required
            label="手机"
            maxlength="11"
            placeholder="请输入"
            type="number"
          />
        </div>
        <div class="input">
          <van-field
            v-model="identity"
            input-align="right"
            is-link
            readonly
            required
            label="身份"
            placeholder="请选择"
            @click="identityPicker"
          />
        </div>
        <div class="input">
          <van-field
            v-model="roomtype"
            input-align="right"
            is-link
            readonly
            required
            label="房型"
            placeholder="请选择"
            @click="roomtypePicker"
          />
        </div>
        <div class="input">
          <van-field
            v-model="money"
            clearable
            input-align="right"
            required
            label="租金"
            placeholder="元/平米/天"
            maxlength="20"
            type="number"
          />
        </div>
        <div class="input">
          <van-field
            v-model="rentalform"
            input-align="right"
            is-link
            readonly
            required
            label="出租形式"
            placeholder="请选择"
            @click="rentalformPicker"
          />
        </div>
        <div class="input noborder">
          <div class="input_t">房屋配置</div>
          <div class="checkBox">
            <van-checkbox-group v-model="welfareSelect" direction="horizontal">
              <div
                class="checkItem"
                :class="{active:item.checked}"
                v-for="item,index in welfare"
                :key="index"
                @click="selectWelfare(item)"
              >
                <van-checkbox :name="item.value">{{item.txt}}</van-checkbox>
              </div>
            </van-checkbox-group>
          </div>
          <!--  -->
        </div>
        <div class="line"></div>
        <div class="input noborder">
          <div class="input_t">详细描述</div>
          <van-field
            v-model="detail"
            rows="4"
            autosize
            type="textarea"
            maxlength="300"
            placeholder="可以详细描述房屋信息"
            show-word-limit
            clearable
          />
        </div>
        <div class="line"></div>
        <div class="input noborder">
          <div class="input_t">照片</div>
          <div class="photo">
            <van-uploader
              v-model="photo"
              multiple
              :max-count="2"
              :max-size="imgMaxSize"
              @oversize="onOversize"
              @delete="deleteImg"
              :after-read="uploadReady"
            />
          </div>
        </div>
        <!--  -->
      </div>
      <div class="submit" @click="submit">确定发布</div>
      <!-- 区域弹窗 -->
      <van-popup v-model="areaPickerShow" position="bottom">
        <van-picker
          title="区域"
          show-toolbar
          :default-index="areaIndex"
          :columns="areaPick"
          @cancel="areaCancel"
          @confirm="areaConfirm"
        />
      </van-popup>
      <!-- 有效期弹窗 -->
      <van-popup v-model="timePickerShow" position="bottom">
        <van-picker
          title="有效期"
          show-toolbar
          :default-index="timeIndex"
          :columns="timePick"
          @cancel="timeCancel"
          @confirm="timeConfirm"
        />
      </van-popup>
      <!-- 身份弹窗 -->
      <van-popup v-model="identityPickerShow" position="bottom">
        <van-picker
          title="身份"
          show-toolbar
          :default-index="identityIndex"
          :columns="identityPick"
          @cancel="identityCancel"
          @confirm="identityConfirm"
        />
      </van-popup>
      <!-- 房型弹窗 -->
      <van-popup v-model="roomtypePickerShow" position="bottom">
        <van-picker
          title="房型"
          show-toolbar
          :default-index="roomtypeIndex"
          :columns="roomtypePick"
          @cancel="roomtypeCancel"
          @confirm="roomtypeConfirm"
        />
      </van-popup>
      <!-- 出租形式弹窗 -->
      <van-popup v-model="rentalformPickerShow" position="bottom">
        <van-picker
          title="出租形式"
          show-toolbar
          :default-index="rentalformIndex"
          :columns="rentalformPick"
          @cancel="rentalformCancel"
          @confirm="rentalformConfirm"
        />
      </van-popup>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      type:"",
      type_id:"",   //类别
      imgMaxSize: 5 * 1024 * 1024, // 最大上传图片大小5M
      mediumquality: 0.7,
      title: "",
      money:"",
      rentalform:"",
      rentalform_id:"",
      rentalformIndex:"",
      rentalformPickerShow: false,
      rentalformPick: [
         { id: "1", text: "整套" },
         { id: "2", text: "单间" },
         { id: "3", text: "床位" }
      ],
      roomtype:"",
      roomtype_id:"",
      roomtypeIndex:"",
      roomtypePickerShow: false,
      roomtypePick: [
         { id: "1", text: "1室0厅" },
         { id: "2", text: "1室1厅" },
         { id: "3", text: "2室1厅" },
         { id: "4", text: "2室2厅" },
         { id: "5", text: "3室1厅" },
         { id: "6", text: "3室2厅" },
         { id: "7", text: "4室及以上" }
      ],
      identity:"",
      identity_id:"",
      identityIndex:"",
      identityPickerShow: false,
      identityPick: [
         { id: "1", text: "个人" },
         { id: "2", text: "经纪人" },
      ],
      area_id: "",
      area: "",
      areaIndex: "",
      areaPickerShow: false,
      areaPick: [{ id: "0", text: "不限" }],
      time_id: "",
      time: "",
      timeIndex: "",
      timePickerShow: false,
      timePick: [
        { id: "0", text: "长期有效" },
        { id: "1", text: "一周" },
        { id: "2", text: "一个月" },
        { id: "3", text: "两个月" },
        { id: "4", text: "一年" }
      ],
      detail: "", //自我描述
      name: "",
      phone: "",
      photo: [],
      photoUrl: [],
      imgs: "",
      welfareSelect: [],
      welfare: [
        //房屋配置
        { txt: "床", value: "bed", checked: false },
        { txt: "衣柜", value: "wardrobe", checked: false },
        { txt: "沙发", value: "sofa", checked: false },
        { txt: "电视", value: "tv", checked: false },
        { txt: "冰箱", value: "refrigerator", checked: false },
        { txt: "洗衣机", value: "washing_machine", checked: false },
        { txt: "空调", value: "air_conditioning", checked: false },
        { txt: "热水器", value: "hot_water", checked: false },
        { txt: "宽带", value: "wifi", checked: false },
        { txt: "暖气", value: "heating", checked: false }
      ],
      bed:false,
      wardrobe:false,
      sofa:false,
      tv:false,
      refrigerator:false,
      washing_machine: false,
      air_conditioning: false,
      hot_water: false,
      wifi: false,
      heating: false
    };
  },
  mixins: [authMixin],
  created() {
    this.getRouteQuery();
    this.getArea();
  },
  methods: {
    getRouteQuery() {
      this.type = this.$route.query.jobType;
      this.type_id = this.$route.query.id;
    },
    onOversize(file) {
      this.$toast("图片大小不能超过5M");
    },
    uploadReady(file) {
      //图片选择回调
      let formData = new FormData();
      formData.append("img", file.file);
      this.$request({
        method: "post",
        url: "/cms/upload/",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        console.log(res);
        if (res.data) {
          this.photoUrl.push(res.data);
        }
      });
    },
    deleteImg(val, index) {
      //图片删除回调
      let deleIndex = index.index;
      let deleImg = this.photoUrl[deleIndex]; //删除的服务器上对应图片
      this.photoUrl.splice(deleIndex, 1);
      this.$request({
        method: "post",
        url: "/cms/deleteImg",
        data: {
          img: deleImg
        }
      }).then(res => {
        console.log(res);
      });
    },
    areaPicker() {
      this.areaPickerShow = true;
    },
    areaCancel() {
      this.areaPickerShow = false;
    },
    areaConfirm(item, index) {
      this.areaPickerShow = false;
      this.areaIndex = String(index);
      this.area = item.text;
      this.area_id = item.id;
    },
    timePicker() {
      this.timePickerShow = true;
    },
    timeCancel() {
      this.timePickerShow = false;
    },
    timeConfirm(item, index) {
      this.timePickerShow = false;
      this.timeIndex = String(index);
      this.time = item.text;
      this.time_id = item.id;
    },
    identityPicker(){
      this.identityPickerShow = true;
    },
    identityCancel(){
      this.identityPickerShow = false;
    },
    identityConfirm(item, index) {
      this.identityPickerShow = false;
      this.identityIndex = String(index);
      this.identity = item.text;
      this.identity_id = item.id;
    },
    roomtypePicker(){
      this.roomtypePickerShow = true;
    },
    roomtypeCancel(){
      this.roomtypePickerShow = false;
    },
    roomtypeConfirm(item, index) {
      this.roomtypePickerShow = false;
      this.roomtypeIndex = String(index);
      this.roomtype = item.text;
      this.roomtype_id = item.id;
    },
    rentalformPicker(){
      this.rentalformPickerShow = true;
    },
    rentalformCancel(){
      this.rentalformPickerShow = false;
    },
    rentalformConfirm(item, index) {
      this.rentalformPickerShow = false;
      this.rentalformIndex = String(index);
      this.rentalform = item.text;
      this.rentalform_id = item.id;
    },
    selectWelfare(item) {
      // 房屋配置
      item.checked = !item.checked;
    },
    getArea() {
      this.$request({
        method: "get",
        url: "/cms/area/"
      }).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "id": "${item.id}",
                "text": "${item.title}"
               }`;
            this.areaPick.push(JSON.parse(objdata));
          });
        }
      });
    },
    subTxtData() {
      let imgs = this.photoUrl.join(',')
      this.$request({
        method: "post",
        url: "/cms/house/",
        data: {
          title: this.title,
          tid: this.type_id,
          area_id: this.area_id,
          valid_time: this.time_id,
          name: this.name,
          phone: this.phone,
          description: this.detail,
          money: this.money,
          imgs: imgs,
          identity: this.identity_id,
          room_type: this.roomtype_id,
          rental_form: this.rentalform_id,
          bed: this.bed,
          wardrobe: this.wardrobe,
          sofa: this.sofa,
          tv: this.tv,
          refrigerator: this.refrigerator,
          washing_machine: this.washing_machine,
          air_conditioning: this.air_conditioning,
          hot_water: this.hot_water,
          wifi: this.wifi,
          heating: this.heating
        }
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$toast.clear();
          this.$toast.success({
            message: "发布成功,请等待审核",
            forbidClick: true,
            duration: 500
          });
          setTimeout(() => {
            this.$router.replace({
              path:"/houseList",
              query:{
                infoType: '1'
              }
            });
          }, 700);
        }
      });
    },
     welfareCheck(type) {
      // 房屋配置
      let _this = this;
      let welfareSelect = this.welfareSelect;
      if (welfareSelect.includes(type)) {
        _this[type] = true;
      } else {
        _this[type] = false;
      }
    },
    submit() {
      this.welfare.forEach(item => {
        this.welfareCheck(item.value);
      });
      let reg = /^[1][3,4,5,7,8][0-9]{9}$/; //手机号正则验证
      let title = this.title;
      let area = this.area;
      let time = this.time;
      let name = this.name;
      let phone = this.phone;
      let money = this.money;
      let identity = this.identity_id
      let roomtype = this.roomtype_id
      let rentalform = this.rentalform_id
      if (!title) {
        this.$toast({
          message: "请输入标题",
          forbidClick: true
        });
      } else if (!area) {
        this.$toast({
          message: "请选择区域",
          forbidClick: true
        });
      } else if (!time) {
        this.$toast({
          message: "请选择有效期",
          forbidClick: true
        });
      } else if (!name) {
        this.$toast({
          message: "请输入联系人",
          forbidClick: true
        });
      } else if (!phone) {
        this.$toast({
          message: "请输入手机号",
          forbidClick: true
        });
      } else if (!reg.test(phone)) {
        this.$toast({
          message: "请输入正确的手机号",
          forbidClick: true
        });
      } else if (!money) {
        this.$toast({
          message: "请输入价格",
          forbidClick: true
        });
      }else if(!identity) {
        this.$toast({
          message: "请选择身份",
          forbidClick: true
        });
      }else if(!roomtype) {
        this.$toast({
          message: "请选择房型",
          forbidClick: true
        });
      }else if(!rentalform) {
        this.$toast({
          message: "请选择出租形式",
          forbidClick: true
        });
      }else {
        this.$toast.loading({
          loadingType: "spinner",
          forbidClick: true,
          duration: 0
        });
        this.subTxtData();
        //发送请求
      }
      // submit End
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.submit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  text-align: center;
  background: #567cf3;
  color: #fff;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.4rem;
}
.form {
  padding-bottom: 1.5rem;
  .line {
    background: #f5f5f5;
    height: 0.2rem;
  }
  .form_t {
    background: #f5f5f5;
    color: #9e9e9e;
    font-size: 0.32rem;
    padding: 0.2rem 0.3rem;
    letter-spacing: 0.03rem;
  }
  .input {
    border-bottom: 1px solid #ebedf0;
    .input_t {
      color: #646566;
      padding: 0.26667rem 0.42667rem 0.05rem 0.42667rem;
      font-size: 0.37333rem;
    }
    .checkBox {
      display: flex;
      justify-content: flex-start;
      padding: 0.26667rem 0.42667rem 0.3rem 0.42667rem;
      .checkItem {
        background: #f6f6f6;
        color: #999;
        text-align: center;
        height: 0.9rem;
        line-height: 0.9rem;
        padding: 0 0.25rem;
        border-radius: 0.85rem;
        margin-top: 0.15rem;
        margin-bottom: 0.15rem;
        margin-right: 0.2rem;
        .van-checkbox {
          height: 100%;
          width: 100%;
        }
      }
      .checkItem.active {
        background: #567cf3;
      }
    }
    .photo {
      padding: 0.26667rem 0.42667rem 0.05rem 0.42667rem;
    }
  }
  .input.noborder {
    border-bottom: none;
  }
}
</style>